import $ from 'jQuery';
global.$ = global.jQuery = $;

global.isDebug = false;
global.__c__ = function(a){
  if(isDebug){
    console.log(a);
  }
}

global.AnimationStart = function($container){
  // console.log("AnimationStart");
  var _$wait = $container.find(".wait");
  _$wait.each(function(){
    var __$this = $(this);
    var __delay = __$this.data("delay");
    var __timeout = setTimeout(function(){
      __$this
        .removeClass("wait")
        .addClass("animation");
      clearTimeout(__timeout);
    }, __delay)
  });
};

global.ResetAnimate = function($container){
  // console.log("ResetAnimate");
  var _$animate = $container.find(".animation");
  var _$animating = $container.find(".animating");
  _$animate.each(function(){
    var __$this = $(this);
    __$this
      .addClass("wait")
      .removeClass("animation");
  });
  _$animating.each(function(){
    var __$this = $(this);
    __$this
      .addClass("waiting")
      .removeClass("animating");
  });

};

class JSONReader {
  constructor($container){
    this.container = $container ? $container : null;
    this.parse = {};
    this.max = 10;
    this.current = 1;
    this.isRead = false;
    this.page = (function(){
      var _query = window.location.search.replace("?", "").split("&");

      for(var p = 0; p < _query.length; p++){
        var q = _query[p].split("=");
        if(q[0] === "page"){
          return q[1] | 0;
        }
      }
      return 1;
    })();
  }

  read(url, callback){
    console.log("read");
    var _this = this;
    var _xhr = new XMLHttpRequest();
    var _callback;
    __c__(typeof (callback));
    if(typeof (callback) === "function"){
      _callback = callback;
      __c__(_callback);
    }else{
      _callback = function(){
      };
    }
    _xhr.open("GET", url);
    _xhr.onload = function(){
      _this.parse = JSON.parse(_xhr.response);
      _this.area = {
        kinki: [], sanyosanin: [], hokuriku: [], kyushu: []
      };
      _this.category = {
        gourmet: [], photogenic: [], activity: [], information: []
      };

      for(var p = 0; p < _this.parse.length; p++){
        var _postObj = _this.parse[p];
        var _id = _this.AddZero(_postObj.post_id);

        var _category = _postObj.category;
        if(_category.indexOf('gourmet') !== -1) _this.category.gourmet.push(_id);
        if(_category.indexOf('photogenic') !== -1) _this.category.photogenic.push(_id);
        if(_category.indexOf('activity') !== -1) _this.category.activity.push(_id);
        if(_category.indexOf('information') !== -1) _this.category.information.push(_id);

        var _area = _postObj.area;
        if(_area.indexOf('kinki') !== -1) _this.area.kinki.push(_id);
        if(_area.indexOf('hokuriku') !== -1) _this.area.hokuriku.push(_id);
        if(_area.indexOf('sanyosanin') !== -1) _this.area.sanyosanin.push(_id);
        if(_area.indexOf('kyushu') !== -1) _this.area.kyushu.push(_id);
      }

      __c__("Loaded");
      _callback();
      _this.isRead = true;
      $(window).trigger('JsonLoaded');
    };
    _xhr.onerror = function(){
    };
    _xhr.send();
  }

  ENtoJA(text){
    var _ja = '';
    switch(text){
      case 'information':
        _ja = 'インフォメーション';
        break;
      case 'activity':
        _ja = 'アクティビティ';
        break;
      case 'photogenic':
        _ja = 'フォトジェニック';
        break;
      case 'gourmet':
        _ja = 'グルメ';
        break;
      case 'kinki':
        _ja = '近畿';
        break;
      case 'hokuriku':
        _ja = '北陸・信越';
        break;
      case 'sanyosanin':
        _ja = '山陽・山陰';
        break;
      case 'kyushu':
        _ja = '九州';
        break;
      default:
        _ja = '';
        break;
    }

    return _ja;
  }

  HTMLfromObj(postObj){
    var _this = this;

    __c__(postObj.post_id);
    var _id = _this.AddZero(postObj.post_id);
    __c__(_id);
    var _title = postObj.title;
    var _areaArr = postObj.area.split(',');
    var _categoryArr = postObj.category.split(',');
    var _url = postObj.url;
    var _date = postObj.date;
    var _isMyfav = postObj.myfav;
    var _imgUrl = "";
    if((_isMyfav | 0) === 1){
      _imgUrl = "/aotabi/feature/" + _id + "/images/";
    }else{
      var _replaceId = _id.replace("_","/");
      _imgUrl = "/aotabi/assets/img/feature/" + _replaceId + "/";
    }

    var _$box = $("<div class='post-box'></div>");
    var _$a = $("<a href='" + _url + "' data-gtm-act='link' data-gtm-lab='/click_count/aotabi/index/banner_feature_" + postObj.post_id +"'></a>");
    var _$thumbs = $("<div class='post--thumb'><span><img src='" + _imgUrl + "kv.jpg' alt='" + _title + "'></span></div>");
    var _$explain = $("<div class='post-explain'></div>");
    var _$title = $("<h3 class='post--ttl'><span>" + _title + "</span></h3>");
    var _$tags = $("<div class='post-tags'></div>");
    if(_categoryArr !== void 0){
      for(var t = 0;t < _categoryArr.length; t++){
        if(_categoryArr[t] !== ""){
          var _$tag = $("<div class='post--tag' data-category='" + _categoryArr[t] + "'>#" + _this.ENtoJA(_categoryArr[t]) + "</div>");
          _$tags.append(_$tag);
        }
      }
    }
    if(_areaArr.length !== 0 && _areaArr !== void 0 && _areaArr !== 0){
      for(var c = 0;c < _areaArr.length; c++){
        if(_areaArr[c] !== ""){
          var _$area = $("<div class='post--tag' data-area='" + _areaArr[c] + "'>#" + _this.ENtoJA(_areaArr[c]) + "</div>");
          _$tags.append(_$area);
        }
      }
    }
    var _$date = $("<p class='post--date'>" + _date + "</p>");
    _$explain.append(_$title);
    _$explain.append(_$tags);
    _$explain.append(_$date);

    _$a.append(_$thumbs)
    _$a.append(_$explain)

    _$box.append(_$a);

    return _$box;
  }

  GetPost(id){
    __c__('getPost');
    var _this = this;
    for(var p = 0; p < _this.parse.length; p++){
      if(_this.AddZero(_this.parse[p].post_id) === id){
        return _this.HTMLfromObj(_this.parse[p]);
      }
    }
    return false;
  }

  OutPutNew($container, callback){
    __c__('OutPut');
    var _this = this;
    var _$container = $container;

    var _max = $container.data("max");
    var _type = $container.data("type") ? $container.data("type"): "";

    _max = (_max !== void 0) ? _max : 0;

    $container.empty();
    for(var i = 0; i < _max; i++){
      if(i === _max) return;
      var _$box = _this.GetPost(_this.AddZero(_this.parse[i].post_id));
      if(_type !== ""){
        _$box.addClass(_type + "-box");
      }
      $container.append(_$box);
    }

    if(typeof(callback) === 'function'){
      callback();
    }
  }

  OutPutLimitAmount($container ,callback){
    __c__('outPutLimitAmount');
    var _this = this;

    if($container.data("posts") === "" || $container.data("posts") === void 0){
      var _category =_this.category[$container.data("category")];
      var _max = $container.data("max");
      var _type = $container.data("type") ? $container.data("type"): "";
      $container.empty();
      for(var c = 0; c < _max; c++){
        var _$box = _this.GetPost(_category[c]);
        if(_type !== ""){
          _$box.addClass(_type + "-box");

          if(_type === "activity"){
            _$box.addClass("swiper-slide");
          }
        }
        $container.append(_$box);
      }
    }else{
      var _posts = $container.data("posts").replace(" ", "").split(",");
      var _max = $container.data("max") ? $container.data("max") : _posts.length;
      var _type = $container.data("type") ? $container.data("type"): "";

      $container.empty();
      for(var i = 0; i < _posts.length; i++){
        if(i === _max) return;
        var _$box = _this.GetPost(_posts[i]);
        if(_type !== ""){
          _$box.addClass(_type + "-box");

          if(_type === "activity"){
            _$box.addClass("swiper-slide");
          }
        }
        $container.append(_$box);
      }
    }



    if(typeof(callback) === "function"){
      callback();
    }
  }

  SetPagination(){
    __c__('SetPagination');
    var _this = this;
    var _$pagination = $("#pagination");
    var _$prevBtn = _$pagination.find(".pagination--prev a");
    var _$nextBtn = _$pagination.find(".pagination--next a");
    var _$lists = _$pagination.find(".pagination-nums").empty();

    var _AddList = function(num){
      var __num = num;
      var __$a = $("<a></a>").text(__num).attr("href", "?page=" + __num.toString());
      var __$list = $("<div class='pagination--list'></div>").append(__$a);
      if((i + 1) === _this.page){
        __$list.addClass('current');
      }
      _$lists.append(__$list);
    }
    var _AddDots = function(){
      var __$list = $("<div class='pagination--list'>・・・</div>");
      _$lists.append(__$list);
    }

    for(var i = 0; i < _this.blocks.length; i++){
      var __num = i + 1;
      var __last = _this.blocks.length - 1;

      if(__last < 10){
        _AddList(__num);
      }else{
        if(i === 0 || i === __last){
          _AddList(__num);
        }else{
          if(_this.page <= 4 && __num <= 6){
            _AddList(__num);
            if(_this.page <= 3 && __num === 6){
              _AddDots();
            }
          }else if(_this.page >= __last - 1 && __num >= __last - 4){
            if(__num === __last - 4){
              _AddDots();
            }
            _AddList(__num);
          }else{
            if(_this.page - 2 <= __num && _this.page + 2 >= __num){
              _AddList(__num);
            }else if(_this.page - 3 === __num){
              if(_this.page - 4 === 1){
                _AddList(__num);
              }else{
                _AddDots();
              }
            }else if(_this.page + 3 === __num){
              if(_this.page + 3 === __last){
                _AddList(__num);
              }else{
                _AddDots();
              }
            }
          }
        }
      }
      if(__num === _this.page){
        _$prevBtn.attr("href", "?page=" + (__num - 1).toString());
        _$nextBtn.attr("href", "?page=" + (__num + 1).toString());
      }
    }

    if(_this.page <= 1){
      _$prevBtn.remove();
    }
    if(_this.page === _this.blocks.length){
      _$nextBtn.remove();
    }
  }

  SetOutputPosts(arr, max){
    __c__('SetOutputPosts');
    var _this = this;
    if(max){
      _this.max = max;
    }
    var _blockLen = (arr.length / _this.max) | 0;
    _this.blocks = [];
    for(var a = 0; a <= _blockLen; a++){
      _this.blocks.push([]);
    }
    for(var p = 0; p < arr.length; p++){
      _this.blocks[(p / _this.max) | 0].push(arr[p]);
    }

    if(_this.page){
      if(_this.page < 1 || _this.page > _this.blocks.length){
        _this.page = 1;
      }
    }

    _this.SetPagination();
  }

  OutputPosts(){
    __c__('OutputPosts');
    var _this = this;
    var _num = _this.page - 1;

    _this.container.empty();
    for(var i = 0; i < _this.blocks[_num].length; i++){
      var isObject = typeof(_this.blocks[_num][i]) === 'object';
      var _$obj;
      if(isObject){
        _$obj = _this.HTMLfromObj(_this.blocks[_num][i]);
      }else{
        _$obj = _this.GetPost(_this.blocks[_num][i]);
      }
      var _$a = _$obj.find("a");
      _this.container.append(_$obj);
      _$a
        .css({opacity: 0})
        .delay(i * 50)
        .animate({opacity: 1}, 400);
    }
    _this.container.css({"min-height": ""});
  }

  AddZero(txt){
    __c__("AddZero start");
    var _txt = txt;
    if(_txt.length === 1){
      _txt = "0" + _txt;
    }
    return _txt;
  }
}
global.JSONReader = JSONReader;

var pow = Math.pow,
  sqrt = Math.sqrt,
  sin = Math.sin,
  cos = Math.cos,
  PI = Math.PI,
  c1 = 1.70158,
  c2 = c1 * 1.525,
  c3 = c1 + 1,
  c4 = ( 2 * PI ) / 3,
  c5 = ( 2 * PI ) / 4.5;
// x is the fraction of animation progress, in the range 0..1
function bounceOut(x) {
  var n1 = 7.5625,
    d1 = 2.75;
  if ( x < 1/d1 ) {
    return n1*x*x;
  } else if ( x < 2/d1 ) {
    return n1*(x-=(1.5/d1))*x + 0.75;
  } else if ( x < 2.5/d1 ) {
    return n1*(x-=(2.25/d1))*x + 0.9375;
  } else {
    return n1*(x-=(2.625/d1))*x + 0.984375;
  }
}
$.extend( $.easing,
  {
    def: 'easeOutQuad',
    swing: function (x) {
      return $.easing[$.easing.def](x);
    },
    easeInQuad: function (x) {
      return x * x;
    },
    easeOutQuad: function (x) {
      return 1 - ( 1 - x ) * ( 1 - x );
    },
    easeInOutQuad: function (x) {
      return x < 0.5 ?
        2 * x * x :
        1 - pow( -2 * x + 2, 2 ) / 2;
    },
    easeInCubic: function (x) {
      return x * x * x;
    },
    easeOutCubic: function (x) {
      return 1 - pow( 1 - x, 3 );
    },
    easeInOutCubic: function (x) {
      return x < 0.5 ?
        4 * x * x * x :
        1 - pow( -2 * x + 2, 3 ) / 2;
    },
    easeInQuart: function (x) {
      return x * x * x * x;
    },
    easeOutQuart: function (x) {
      return 1 - pow( 1 - x, 4 );
    },
    easeInOutQuart: function (x) {
      return x < 0.5 ?
        8 * x * x * x * x :
        1 - pow( -2 * x + 2, 4 ) / 2;
    },
    easeInQuint: function (x) {
      return x * x * x * x * x;
    },
    easeOutQuint: function (x) {
      return 1 - pow( 1 - x, 5 );
    },
    easeInOutQuint: function (x) {
      return x < 0.5 ?
        16 * x * x * x * x * x :
        1 - pow( -2 * x + 2, 5 ) / 2;
    },
    easeInSine: function (x) {
      return 1 - cos( x * PI/2 );
    },
    easeOutSine: function (x) {
      return sin( x * PI/2 );
    },
    easeInOutSine: function (x) {
      return -( cos( PI * x ) - 1 ) / 2;
    },
    easeInExpo: function (x) {
      return x === 0 ? 0 : pow( 2, 10 * x - 10 );
    },
    easeOutExpo: function (x) {
      return x === 1 ? 1 : 1 - pow( 2, -10 * x );
    },
    easeInOutExpo: function (x) {
      return x === 0 ? 0 : x === 1 ? 1 : x < 0.5 ?
        pow( 2, 20 * x - 10 ) / 2 :
        ( 2 - pow( 2, -20 * x + 10 ) ) / 2;
    },
    easeInCirc: function (x) {
      return 1 - sqrt( 1 - pow( x, 2 ) );
    },
    easeOutCirc: function (x) {
      return sqrt( 1 - pow( x - 1, 2 ) );
    },
    easeInOutCirc: function (x) {
      return x < 0.5 ?
        ( 1 - sqrt( 1 - pow( 2 * x, 2 ) ) ) / 2 :
        ( sqrt( 1 - pow( -2 * x + 2, 2 ) ) + 1 ) / 2;
    },
    easeInElastic: function (x) {
      return x === 0 ? 0 : x === 1 ? 1 :
        -pow( 2, 10 * x - 10 ) * sin( ( x * 10 - 10.75 ) * c4 );
    },
    easeOutElastic: function (x) {
      return x === 0 ? 0 : x === 1 ? 1 :
        pow( 2, -10 * x ) * sin( ( x * 10 - 0.75 ) * c4 ) + 1;
    },
    easeInOutElastic: function (x) {
      return x === 0 ? 0 : x === 1 ? 1 : x < 0.5 ?
        -( pow( 2, 20 * x - 10 ) * sin( ( 20 * x - 11.125 ) * c5 )) / 2 :
        pow( 2, -20 * x + 10 ) * sin( ( 20 * x - 11.125 ) * c5 ) / 2 + 1;
    },
    easeInBack: function (x) {
      return c3 * x * x * x - c1 * x * x;
    },
    easeOutBack: function (x) {
      return 1 + c3 * pow( x - 1, 3 ) + c1 * pow( x - 1, 2 );
    },
    easeInOutBack: function (x) {
      return x < 0.5 ?
        ( pow( 2 * x, 2 ) * ( ( c2 + 1 ) * 2 * x - c2 ) ) / 2 :
        ( pow( 2 * x - 2, 2 ) *( ( c2 + 1 ) * ( x * 2 - 2 ) + c2 ) + 2 ) / 2;
    },
    easeInBounce: function (x) {
      return 1 - bounceOut( 1 - x );
    },
    easeOutBounce: bounceOut,
    easeInOutBounce: function (x) {
      return x < 0.5 ?
        ( 1 - bounceOut( 1 - 2 * x ) ) / 2 :
        ( 1 + bounceOut( 2 * x - 1 ) ) / 2;
    }
  });

global._isSP = undefined;

if(window.outerWidth < 751){
  _isSP = true;
}else{
  _isSP = false;
}

var CommonInit = function(){
  var $window = $(window);
  var $body = $("body").addClass("loaded");
  var $header = $("header");
  var _headerHeight = $header.outerHeight();

  var $nav = $(".nav");
  var $navButton = $(".nav--btn");
  var _currentPos = $window.scrollTop();
  var NavOpenClose = function(){
    if($nav.hasClass("nav--open")){
      $body.removeClass("is-modal").css({paddingRight: ""});
      $nav.css({opacity: ""});
      $nav.find('.nav-content').animate({ scrollTop: 0 }, 0);
      $nav.removeClass("nav--open");
    }else{
      var scrollbarWidth = window.innerWidth - document.body.clientWidth;
      $nav.addClass("nav--open");
      $body.addClass("is-modal").css({paddingRight: scrollbarWidth});
    }
  };
  var NavAppearHide = function(){
    if($window.scrollTop() < _headerHeight){
      $navButton.addClass("nav-top-fixed").removeClass("nav-hide nav-appear");
    }else{
      if(_currentPos <= $window.scrollTop()){
        // 下
        $navButton.addClass("nav-hide").removeClass("nav-top-fixed nav-appear");
      }else{
        // 上
        $navButton.addClass("nav-appear").removeClass("nav-top-fixed nav-hide");
      }
    }
    _currentPos = $window.scrollTop();
  };
  $navButton.on("click", NavOpenClose);

  // ScrollAnimatiopn
  var setAnimation = function(){
    // console.log("setAnimation:");
    // console.log("scrollTop: " + $(scrollTarget).scrollTop());
    var $section = $(".waiting");
    var sectionLen = $section.length;
    var timing, _height, mainHeight, mainTop;
    _height = $window.height();
    mainTop = 0;
    timing = _height - (_height / 4);

    for(var i = 0; i < sectionLen; i++){
      var $wait = $($section[i]);
      var offsetTop;
      offsetTop = $wait.offset().top;

      if($window.scrollTop() + timing >= offsetTop && $window.scrollTop() <= offsetTop){
        var _delay = $wait.data("delay");
        if(_delay === void 0){
          _delay = 0;
        }
        $wait.delay(_delay).queue(function(){
          var __$this = $(this);
          __$this.removeClass("waiting").addClass('animating');
          __$this.dequeue();
        });
      }
    }
    if(sectionLen === 0){
      $window.off("scroll", AnimationScroller);
    }
  };

  var AnimationScroller = function(){
    NavAppearHide();
    setAnimation();
  };

  $window.on("scroll", AnimationScroller);
  AnimationScroller();

  $(".pagetop").on("click", function(){
    $("html, body").stop().animate({scrollTop: 0}, 1000, "easeInOutCirc");
  });

  $(".anchor").on("click", function(){
    var _$this = $(this);
    var _$target = $(_$this.attr("href"));
    $("html, body").stop().animate({scrollTop: _$target.offset().top}, 1000, "easeInOutCirc");
    return false;
  });

  var ClickedTagToLink = function(event){
    event.preventDefault();
    event.stopPropagation();

    var _$this = $(event.currentTarget);
    var _category = _$this.attr('data-category');
    var _area = _$this.attr('data-area');

    if(_category){
      __c__("category: " + _category);
      window.location.href = "/aotabi/category/" + _category + "/";
    }
    if(_area){
      __c__("area: " + _area);
      window.location.href = "/aotabi/area/" + _area + "/";
    }
  }
  $body.on("click", ".post--tag, .article--tag", ClickedTagToLink);

  var Resize = function(){
    _headerHeight = $header.outerHeight();
    if($window.width() < 768){
      _isSP = true;
    }else{
      _isSP = false;
    }
  };
  $window.on("resize", Resize);
  Resize();

};

window.addEventListener("load", CommonInit);
